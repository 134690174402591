import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import * as styles from '../Styles';
import * as mui from '@mui/material';
import { Helmet } from 'react-helmet';
import ContactMarket from '../components/ContactMarket';
import { useLocation } from "react-router-dom";
import * as constants from '../constants';
import { ThemeProvider } from '@mui/material';

const Contact = () => {

    const location = useLocation();

    return (
        <ThemeProvider theme={styles.theme}>
            <Helmet><title>Raglan Kettle Korn | Contact</title></Helmet>
            <Grid container flexDirection={'column'} sx={{justifyContent: 'flex-start', xs: 12}}>
                {/* <Grid container item position={'absolute'} flexDirection={'row'} sx={{xs:12, justifyContent: 'center'}}> */}
                <mui.Grid item container position={'absolute'} sx={{justifyContent: 'center', zIndex: -1}}>
                    <CardMedia
                        component="img"
                        sx={styles.splashImageStyle}
                        src={constants.imageURL + '/home_image.jpg'}
                        alt="Home Image"/>
                </mui.Grid>
                <Grid container item flexDirection={'row'} xs={12} sx={{alignItems: 'flex-start', justifyContent: 'center', zIndex: 10}}>
                    <mui.Grid item lg={4} md={5} sm={7} xs={10}>   
                    <mui.Card sx={styles.contactCard}>
                        <mui.Grid item xs={12}>   
                        <ContactMarket key={location.state.marketID} marketID={location.state.marketID} marketName={location.state.marketName}/>
                        </mui.Grid>
                    </mui.Card>
                    </mui.Grid>   
                </Grid>
                {/* </Grid> */}
            </Grid>
                
        </ThemeProvider>
    )
}

export default Contact;