import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import * as mui from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as constants from '../constants';
import { ThemeProvider } from '@mui/material';
import * as styles from '../Styles';
import { isMobile } from 'react-device-detect';

const pages = ['Home', 'Our Story', 'Our Artisan Popcorn', 'Our Markets', 'Place Market Preorder'];
const pageMap: Map<string, string> = new Map([
  ['Home', '/home'],
  ['Our Story', '/about'],
  ['Our Artisan Popcorn', '/popcorn'],
  ['Our Markets', '/markets'],
  ['Place Market Preorder', '/order'],
  ['Contact', '/contact']
]);

function NavBar() {

  const navigate = useNavigate();
  const location = useLocation();

  const [contactAnchorEl, setContactAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(contactAnchorEl);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);


  const handleContactClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setContactAnchorEl(event.currentTarget);
  };
  const handleContactClose = () => {
    setIsDrawerOpen(false);
    setContactAnchorEl(null);
  };

  const navigateAndClose = (market: Market) => {
    handleContactClose();
    navigate('/contact', {state: {marketID: market.id, marketName: market.name}});
  }


  return (
    <ThemeProvider theme={styles.theme}>
    <AppBar position="sticky" elevation={0} sx={{backgroundColor:"#fff7ed", width: "calc(100vw - 17px)"}}>
      <Toolbar disableGutters>
        <mui.ButtonBase
          onClick={() => {navigate('/home')}}
          disableRipple
          >
          <img src={constants.imageURL + '/rkklogo.png'} alt="rkk logo" width='150px' height='auto'/>
        </mui.ButtonBase>
        <mui.Grid container justifyContent={'center'}>
        { isMobile ? 
        <mui.Grid container item xs={10} sx={{ justifyContent: 'flex-end', alignItems: 'flex-start'}}>
          <mui.IconButton
            edge="start"
            color="default"
            aria-label="menu"
            onClick={() => setIsDrawerOpen(true)}
          >
            <MenuIcon />
          </mui.IconButton>
          <mui.Drawer  
            PaperProps={{
              sx: styles.menuDrawerStyle
              
            }}
            open={isDrawerOpen} 
            onClose={() => setIsDrawerOpen(false)}>
            <mui.List>
              {/* <mui.ListItemButton>
                <mui.ListItemText primary="Home" />
              </mui.ListItemButton> */}
              {pages.map((page) => (
                <mui.Grid item xs={12} sx={{}}>
                <mui.ButtonBase
                  key={page}
                  onClick={() => {navigate((pageMap.get(page) as string)); setIsDrawerOpen(false)}}
                  disableRipple
                  sx={{ m: 1, p: 1, textAlign: 'left', my: 2, color: 'black', display: 'block', width: '100%'}}
                >
                  {page.toUpperCase()}
                </mui.ButtonBase>
                </mui.Grid>
              ))}
              <mui.ButtonBase
                key={'Contact'}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleContactClick}
                disableRipple
                sx={{m: 1, p: 1, textAlign: 'left', my: 2, color: 'black', display: 'block', width: '100%'}}
              >
              CONTACT
              </mui.ButtonBase>
            </mui.List>
          </mui.Drawer>
          <mui.Menu
            id="basic-menu"
            anchorEl={contactAnchorEl}
            open={open}
            onClose={handleContactClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            disableScrollLock={true}
          >
            <mui.MenuItem onClick={() => {navigateAndClose({id: 999, name: "General Enquiry", day: 'Saturday'})}}>General Enquiry</mui.MenuItem>
            {Array.from(constants.markets).map((market) => {return <mui.MenuItem key={market[1].name} onClick={() => {navigateAndClose(market[1])}}>{market[1].name}</mui.MenuItem>})}
          </mui.Menu>
        </mui.Grid>
        :
        <mui.Grid container item xs={10} sx={{ justifyContent: 'space-evenly', alignItems: 'flex-start'}}>
          {pages.map((page) => (
            <mui.Grid item xs={2} sx={{}}>
            <mui.ButtonBase
              key={page}
              onClick={() => {navigate((pageMap.get(page) as string))}}
              disableRipple
              sx={{ fontWeight: (pageMap.get(page) as string) === (location.pathname as string) ? 'bold' : 'none', my: 2, color: 'black', display: 'block', width: '100%', height: '4vh', ":hover": {fontWeight: 'bold'}}}
            >
              {page.toUpperCase()}
            </mui.ButtonBase>
            </mui.Grid>
          ))}
          <mui.Grid item xs={2} sx={{}}>
          <mui.ButtonBase
            key={'Contact'}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleContactClick}
            disableRipple
            sx={{fontWeight: ('/contact') === (location.pathname as string) ? 'bold' : 'none', my: 2, color: 'black', display: 'block', width: '100%', height: '4vh', ":hover": {fontWeight: 'bold'}}}
            >
              CONTACT
              </mui.ButtonBase>
            </mui.Grid>
          <mui.Menu
            id="basic-menu"
            anchorEl={contactAnchorEl}
            open={open}
            onClose={handleContactClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            // sx={{  
            //   '.MuiMenu-paper': { backgroundColor: '#fff7ed', }
            // }}
            disableScrollLock={true}
          >
            <mui.MenuItem onClick={() => {navigateAndClose({id: 999, name: "General Enquiry", day: 'Saturday'})}}>General Enquiry</mui.MenuItem>
            {Array.from(constants.markets).map((market) => {return <mui.MenuItem key={market[1].name} onClick={() => {navigateAndClose(market[1])}}>{market[1].name}</mui.MenuItem>})}
          </mui.Menu>
        </mui.Grid>
        }
        </mui.Grid>
      </Toolbar>
    </AppBar>
    </ThemeProvider>
  );
}
export default NavBar;