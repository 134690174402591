import { useState } from 'react';
import { useNavigate } from 'react-router';
import ContactDetails from '../components/Preorder/ContactDetails';
import * as constants from '../constants';
import * as mui from '@mui/material';
import { API } from 'aws-amplify';
import * as styles from '../Styles';
import { ThemeProvider } from '@mui/material';

type ContactProps = {
    marketID: number,
    marketName: string 
  }

const ContactMarket = (props: ContactProps) => {

    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const [submitted, setSubmitted] = useState<boolean>(false);

    const navigate = useNavigate();

    const contactBody = {
        'firstName': firstName,
        'lastName': lastName,
        'email': email,
        'phone': phone,
        'message': message,
        'market': props.marketName
    }

    const postForm = () => {
        setSubmitted(true);
        API.post(constants.API, '/contact', {body: contactBody})
        .then(response => {
            
        })
        .catch(error => {
            console.log(error)
        })
    }

    return (
        <div>
            {!submitted ? 
            <ContactDetails 
            title={props.marketName}
            subtitle={"We'd love to hear from you!"}
            firstName={firstName} 
            setFirstName={setFirstName} 
            lastName={lastName} 
            setLastName={setLastName}
            email={email}
            setEmail={setEmail}
            phone={phone}
            setPhone={setPhone}
            message={message}
            setMessage={setMessage}
            advance={() => {postForm()}}
            back={() => {navigate('../home')}}
            advanceText="Send Message"
            />
            : 
            <mui.Grid container item justifyContent={'center'} sx={{padding:'5%'}} gap={3} flexDirection={'column'}>
                <mui.Grid container flexDirection={'row'} justifyContent={'center'}>
                <img src={constants.imageURL + '/rkklogo.png'} alt="rkk logo" width='150px' height='auto'/>
                </mui.Grid>
                <ThemeProvider theme={styles.theme}>
                    <mui.Typography variant='h5' display='block'>
                        Message sent. 
                    </mui.Typography>
                    <mui.Typography variant='h5' display='block'>
                        Thanks for contacting us!
                    </mui.Typography>
                </ThemeProvider>
            </mui.Grid>
            }
        </div>
    )
}

export default ContactMarket;