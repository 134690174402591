import * as mui from '@mui/material';
import * as styles from '../../Styles';
import FormControl from '@mui/material/FormControl';
import {useState} from 'react';


type PreorderState = {
  title?: string,
  subtitle?: string,
  firstName: string,
  setFirstName: Function,
  lastName: string,
  setLastName: Function,
  email: string,
  setEmail: Function,
  phone: string,
  setPhone: Function,
  message: string,
  setMessage: Function,
  advance: Function,
  advanceText?: string,
  back?: Function
}

function ContactDetails(props: PreorderState) {

  const validEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  }

  const validPhone = (phone: string) => {
    return /^(\+\d{1,3}[- ]?)?\d{9,11}$/.test(phone);
  }

  const [emailError, setEmailError] = useState<boolean>(props.email !== '' && !validEmail(props.email));
  const [phoneError, setPhoneError] = useState<boolean>(props.phone !== '' && !validPhone(props.phone));
  const emailErrorText = "Please enter a valid email address.";
  const phoneErrorText = "Please enter a valid phone number.";

  const handleChangeEmail = (event) => {
    props.setEmail(event.target.value);
    if (!validEmail(event.target.value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  }

  const handleChangePhone = (event) => {
    props.setPhone(event.target.value);
    if (!validPhone(event.target.value)) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
  }

  return (
    <div>
      <mui.Grid container textAlign={'left'} flexDirection={'column'} gap='0px'>
        <mui.ThemeProvider theme={styles.theme}>
          <mui.Typography variant="h4">{props.title || "Your Details"}</mui.Typography>
        </mui.ThemeProvider>
        <mui.Grid item xs={12}>
          <p>{props.subtitle || "Let us know how to get in touch. We'll send an email to confirm your preorder."}</p>
          <FormControl sx={{ m: 1, minWidth: 120, width: '90%', gap: '20px' }}>
            <mui.TextField
              label="First Name"
              value={props.firstName}
              required
              onChange={(event) => {props.setFirstName(event.target.value)}}
            />
            <mui.TextField
              label="Last Name"
              value={props.lastName}
              required
              onChange={(event) => {props.setLastName(event.target.value)}}
            />
            <mui.TextField
              label="Email"
              value={props.email}
              error={emailError}
              helperText={emailError && emailErrorText}
              required
              onChange={handleChangeEmail}
            />
            <mui.TextField
              label="Phone Number"
              value={props.phone}
              error={phoneError}
              helperText={phoneError && phoneErrorText}
              required
              onChange={handleChangePhone}
            />
            <mui.TextField
              label="Message"
              value={props.message}
              size='medium'
              multiline
              onChange={(event) => {props.setMessage(event.target.value)}}
            />
          </FormControl>
          <mui.Grid item>
          </mui.Grid>
          <hr></hr>
        </mui.Grid>
        <mui.Grid container flexDirection={'row'} justifyContent={'space-between'}>
          <mui.Grid item xs={2}>
          <mui.Button
            fullWidth
            variant='contained'
            onClick={() => {
              props.back && props.back();
              setEmailError(!validEmail(props.email));
              setPhoneError(!validPhone(props.phone));
            }}
          >
            Back
          </mui.Button>
          </mui.Grid>
          <mui.Grid item xs={9.5}>
          <mui.Button 
            fullWidth
            variant="contained"
            disabled={!props.firstName || !props.lastName || !props.email || !props.phone || emailError || phoneError}
            onClick={() => {props.advance()}}>
                {props.advanceText || "Preorder Summary"}
          </mui.Button>
          </mui.Grid>
        </mui.Grid>
      </mui.Grid>
    </div>
  );
}
export default ContactDetails;