import * as mui from '@mui/material'

function OurArtisanPopcornText() {

  return (
    <div>
      <mui.Grid container  textAlign={'left'} flexDirection={'column'}>
      <p>
        As you open a bag (or two, or three!) of delicious gourmet Kettle Korn, you may have wondered
      </p>
      <mui.Typography variant="h5" >Where does this tasty snack come from?</mui.Typography>
      <p>
        Kettle Korn is made of corn, the end. Ha!   The answer may surprise you! 
      </p>
      <mui.Typography variant="h5" textAlign={'left'}>Is popcorn made from maize or corn?</mui.Typography>
      <p>
        Maize can refer to what is grown in the field, whereas corn refers to the harvested product, or the food at the market or on your dinner plate. Depending on where you are, corn can reference a variety of different grains, but maize is always referring to the same crop, which we commonly call corn. 

        The six major types of maize are dent corn, flint corn, pod corn, popcorn, flour corn, and sweet corn. 

        When you're enjoying corn on the cob, you're eating sweet corn. When you're dipping a tortilla chip into fresh guacamole, you're eating a chip made from dent corn. But when you're enjoying some of Raglan Kettle Korn, well those are popcorn kernels! What makes popcorn kernels different than the other kinds of corn (besides being the tastiest of them all) is the higher starch content in every kernel. The extra starch is what creates the white fluffiness of popcorn when it is combined with heat. Popcorn kernels have a hard outer shell that keeps the starch and moisture inside. That’s why they are a lot crunchier than traditional sweet corn! 

        Popcorn, like all six types of corn, is a cereal grain and originates from a wild grass. Its scientific name is Zea mays everta, a special kind of flint corn. Though it looks like a typical corn kernel, this particular variety is the only one that has the ability to pop and turn into a delicious snack.  
      </p>
      <mui.Typography variant="h5" textAlign={'left'}>Making Kettle Korn or Popcorn</mui.Typography>
      <p>
        Classic popcorn nowadays can be made using multiple cooking methods. Aside from your classic microwave popcorn bag, you can either oil-pop or air-pop your popcorn on a stovetop. Oil-popped popcorn involves heating oil in a stovetop and adding kernels with a lid covering. Air-popped popcorn replaces the oil with a small splash of water, and it is a healthy alternative to oil-popped popcorn. 

        Now, kettle korn is a little more complex! Kettle Korn gets its name from its preferred method of preparation, which is in a cast-iron or stainless-steel kettle. Another key difference is Kettle Korn's use of sugar. Along with oil and salt, sugar is added to the kettle and requires constant stirring to avoid burning the sugar. A little bit more work for a little sweeter treat! 

        But it is even easier to order your favourite Raglan Kettle Korn already popped and ready to eat? You bet! 
      </p>
      <mui.Typography variant="h5" textAlign={'left'}>Saving the Cravings</mui.Typography>
      <p>
        If you manage to not go through an entire bag of Raglan Kettle Korn and have leftovers for your midnight snack or family movie night, it is best stored inside an airtight container in a cabinet or fridge. We recommend this for up to three weeks or for even longer storage, consider keeping the popcorn inside your freezer. Using this method of proper storage will save the taste and freshness of your beloved popcorn. So, the next time you have a chance to show off your popcorn knowledge, you can answer the question and you won't have to worry about your gourmet popcorn going stale anymore!  
      </p>
      <mui.Typography variant="h5" textAlign={'left'}>Two Basic Shapes</mui.Typography>
      <p>
        Popcorn comes in two basic shapes butterfly and mushroom. These are terms used to describe the shape of the popped kernels, not where the kernels come from, or the flavour. But doesn’t mushroom flavoured popcorn sound interesting? It might actually be pretty tasty!  

        Most of us are familiar with butterfly popcorn. That's the kind of popcorn you make at home and get at the movie theatre. Mushroom popcorn is not as common but is used by gourmet popcorn makers like Raglan Kettle Korn because of its sturdiness and ability to hold its shape under heavy popcorn sweet & salty coatings, not to mention its great taste experience. 
      </p>
      </mui.Grid>
    </div>
  );
}
export default OurArtisanPopcornText;