import * as mui from '@mui/material';
import * as styles from '../../Styles';
import PreorderItemQuantitySelector from './PreorderItemQuantitySelector';

type PreorderState = {
  products: Array<any>, //product names and prices
  callbacks: Map<string, Function>,
  quantities: Map<string, number>,
  orderTotal: number,
  minOrderValue: number,
  advance: Function
}

function PreorderQuantity(props: PreorderState) {

  const clearQuantities = () => {
    // sets quantity of each item to 0
    props.callbacks.forEach((callback) => {
      callback(0);
    })
  };

  const minimumValueErrorText = `For orders below $${props.minOrderValue}, come visit your local market.`;

  return (
    <div>
      <mui.Grid container textAlign={'left'} flexDirection={'column'} gap='10px'>
        <mui.ThemeProvider theme={styles.theme}>
          <mui.Typography variant="h4">Order Quantity</mui.Typography>
        </mui.ThemeProvider>
        {
          props.products.map((product, i) => {return <PreorderItemQuantitySelector key={i} name={product.name} price={product.price} refillPrice={product.refillPrice} setQuantity={props.callbacks.get(product.name) as Function} quantity={props.quantities.get(product.name) || 0}/>})
        }
        <mui.Grid container item xs={4.5} justifyContent='center'>
          <mui.Button variant='contained' onClick={clearQuantities} sx={{width:180}}>Clear Quantities</mui.Button>
        </mui.Grid>
        <mui.Grid item xs={12}>
          <hr></hr>
        </mui.Grid>
        <mui.Grid container flexDirection={'row'}>
          <mui.Grid item xs={10}>
            <mui.Typography variant="h6">Preorder Total: (pay at market)</mui.Typography>
          </mui.Grid>
          <mui.Grid item xs={1} textAlign='right'>
            <mui.Typography key={props.orderTotal} variant='h6'>${props.orderTotal.toFixed(2)}</mui.Typography>
          </mui.Grid>
        </mui.Grid>
        <mui.Button 
          variant="contained"
          disabled={props.orderTotal >= props.minOrderValue ? false : true}
          onClick={() => {props.advance()}}>
              Select market and time &gt;</mui.Button>
        {props.orderTotal < props.minOrderValue && <mui.Typography color='error'>{minimumValueErrorText}</mui.Typography>}
      </mui.Grid>
    </div>
  );
}
export default PreorderQuantity;