import * as mui from '@mui/material';
import * as styles from '../../Styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import dayjs, { Dayjs } from 'dayjs';


type PreorderState = {
  markets: Map<number, Market>,
  selectedMarket: number,
  setSelectedMarket: Function,
  selectedDate: Dayjs | null,
  setSelectedDate: Function,
  selectedTime: string,
  setSelectedTime: Function,
  advance: Function,
  back: Function
}

function PreorderMarket(props: PreorderState) {

  function onlySaturdays(date: Dayjs) {
    return date.day() !== 6;
  }

  function onlySundays(date: Dayjs) {
    return date.day() !== 0;
  }

  const marketArray = Array.from(props.markets.values());

  let allowedDayFunction = onlySaturdays;
  if (props.markets.get(props.selectedMarket)?.day === 'Sunday') {
    allowedDayFunction = onlySundays;
  }

  const today = dayjs();

  const collectionTimes = ['8:00am', '8:30am', '9:00am', '9:30am', '10:00am', '10:30am', '11:00am'];

  return (
    <div>
      <mui.Grid container textAlign={'left'} flexDirection={'column'} gap='10px'>
        <mui.ThemeProvider theme={styles.theme}>
          <mui.Typography variant="h4">Select Market</mui.Typography>
        </mui.ThemeProvider>
        <mui.Typography>Select your local market and let us know the time you plan to collect the preorder.</mui.Typography>
        <mui.Typography>We accept eftpos as well as cash.</mui.Typography>
        <mui.Grid item container xs={12} flexDirection={'column'} gap='10px'>
        <mui.Grid item>
          <FormControl sx={{ m: 1, minWidth: 120, width: '90%'}}>
            <InputLabel id="market-selector">Select Market</InputLabel>
            <Select
              labelId="market-selector"
              id="market-selector-helper"
              value={props.selectedMarket.toString()}
              label="select Market"
              onChange={(event) => {props.setSelectedMarket(event.target.value)}}
            >
              {marketArray.map((market) => {return <MenuItem key={market.id} value={market.id}>{market.name}</MenuItem>})}

            </Select>
          </FormControl>
          </mui.Grid>
          <mui.Grid item>
          <FormControl sx={{ m: 1, minWidth: 120, width: '90%'}}>
            <MobileDatePicker 
              format="DD/MM/YYYY"
              value={props.selectedDate} 
              minDate={today.add(1, 'day')} 
              label="Collection Date"
              shouldDisableDate={allowedDayFunction} 
              onChange={(newDate) => props.setSelectedDate(newDate)}/>
          </FormControl>
          </mui.Grid>
          <mui.Grid item>
          <FormControl sx={{ m: 1, minWidth: 120, width: '90%'}}>
            <InputLabel id="time-selector">Collection Time</InputLabel>
            <Select
              labelId="time-selector"
              id="time-selector-helper"
              value={props.selectedTime}
              label="Collection Time"
              onChange={(event) => {props.setSelectedTime(event.target.value)}}
            >
              {collectionTimes.map((time) => {return <MenuItem key={time} value={time}>{time}</MenuItem>})}
            </Select>
          </FormControl>
          </mui.Grid>
        </mui.Grid>
        <mui.Typography>Please note that preorders that aren't collected by 11:00am may be sold on to others.</mui.Typography>
        <hr></hr>
        <mui.Grid container flexDirection={'row'} justifyContent={'space-between'}>
          <mui.Grid item xs={2}>
          <mui.Button
            fullWidth
            variant='contained'
            onClick={() => {props.back()}}
          >
            Back
          </mui.Button>
          </mui.Grid>
          <mui.Grid item xs={9.5}>
          <mui.Button 
            fullWidth
            variant="contained"
            disabled={props.selectedDate === null || (props.markets.get(props.selectedMarket)?.day === 'Saturday' && props.selectedDate.day() !== 6) || (props.markets.get(props.selectedMarket)?.day === 'Sunday' && props.selectedDate.day() !== 0)}
            onClick={() => {props.advance()}}>
                Enter your details &gt;
          </mui.Button>
          </mui.Grid>
        </mui.Grid>
      </mui.Grid>
    </div>
  );
}
export default PreorderMarket;