import * as mui from '@mui/material';
import * as styles from '../Styles';
import { Helmet } from 'react-helmet';

const NotFound = () => {
    return (
        <div>
            <Helmet><title>Raglan Kettle Korn | 404</title></Helmet>
            <mui.ThemeProvider theme={styles.theme}>
                <mui.Typography variant='h3'>Under Construction</mui.Typography>
            </mui.ThemeProvider>
        </div>
    )
}

export default NotFound;