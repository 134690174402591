import React from 'react';
import './App.css';
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import Home from "./pages/Home";
import OurStory from "./pages/OurStory";
import OurArtisanPopcorn from './pages/OurArtisanPopcorn';
import OurMarkets from "./pages/OurMarkets";
import PlacePreorder from "./pages/PlacePreorder";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import { Helmet } from 'react-helmet';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <div className="App">
      <Helmet>
        <style>{'body {background-color: #fff7ed; }'}</style>
      </Helmet>
      <Router>
        <div style={{display: 'flex', flexDirection: 'column', minHeight:'100vh'}}>
        <div style={{flex: 1}}>
          <NavBar />
          <Routes>
            <Route path="/home" element={<Home/>}/>
            <Route path="/about" element={<OurStory/>}/>
            <Route path="/popcorn" element={<OurArtisanPopcorn/>}/>
            <Route path="/markets" element={<OurMarkets/>}/>
            <Route path="/order" element={<PlacePreorder/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="/" element={<Navigate replace to="/home" />} />
            <Route path="*" element={<NotFound/>}/>
          </Routes>
        </div>
          <Footer />
        </div>
      </Router>
    </div>
    </LocalizationProvider>
  );
}

export default App;
