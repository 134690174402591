export const cambridgeMarketText = `
Located in Victoria Square, a tree ringed park on the corner of Victoria and Alpha streets, it is on Saturdays from 8:00 - noon.
There is a variety of live music every week and a great choice of produce and artisanal food on offer.`;

export const hamiltonMarketText = `
This all-weather market is located in and around The Barn at Claudlands Event Centre on Brooklyn Road. It is on Sundays from 8:00 - noon
with plenty of parking available. With live music and more than 40 stalls to peruse, it's a great way to spend a Sunday morning.`;

export const greyLynnMarketText = `
This hub of the community is on every Sunday from 8:30 - noon at 510 Richmond Road. There are stalls both inside and outside and a
wonderful vibrant atmosphere full of delicious aromas and gorgeous locally produced food.`;

export const taurangaMarketText = `
Situated at the Tauranga Primary School, 31 Fifth Avenue on Saturdays from 7:45 - noon. This is a thriving bustling market that has been
connecting growers/producers with eager customers for more than 15 years.`;

export const taupoMarketText = `
Situated at Northcroft Reserve beside the beautiful Lake Taupō with epic mountain views, an exciting market has erupted.
With a range of gourmet foods, over 50 stalls and amazing local performers, enjoy an amazing atmosphere for locals & visitors alike from 9:00 - 1:00 on Sundays.
`;

export const markets: Map<number, Market> = new Map([
    [1, {id: 1, name: 'Cambridge Farmers Market', day: 'Saturday'}],
    [2, {id: 2, name: 'Grey Lynn Farmers Market', day: 'Sunday'}],
    [3, {id: 3, name: 'Hamilton Farmers Market', day: 'Sunday'}],
    [4, {id: 4, name: 'Tauranga Farmers Market', day: 'Saturday'}],
    [5, {id: 5, name: 'Market Central Taupō', day: 'Sunday'}]
  ])

export const imageURL = 'https://raglankettlekorn-media.s3.ap-southeast-2.amazonaws.com/images';

// export const apiEndpoint = 'https://f7037g65i5.execute-api.ap-southeast-2.amazonaws.com/staging';
export const API = 'emailAPI';