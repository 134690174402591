import * as mui from '@mui/material';
import * as styles from '../Styles';
import {ThemeProvider } from '@mui/material';

function OurStoryText() {

  return (
    <div>
      <mui.Grid container textAlign={'left'} spacing={5}>
        <mui.Grid item>
          <ThemeProvider theme={styles.theme}>
            <mui.Typography variant="h4">How it began</mui.Typography>
          </ThemeProvider>
        </mui.Grid>
        <mui.Grid item>
        <mui.Typography>
          Let us introduce ourselves -- we are Kevin and Suzanne McGarvey, owners and operators of Raglan Kettle Korn. It all came about quite unexpectedly in 2021 when Kevin sold his electrical business in Auckland but was not ready to retire at the grand old age of 57.
          We had already executed our plan to exit Auckland three years prior as we are both small town people at heart.
        </mui.Typography>
        </mui.Grid>
        <mui.Grid item>
        <mui.Typography>
          We love Raglan with it's eclectic community and spectacular geography and feel very much at home here.
          Kevin knew he wanted to find something to occupy his time (how much golf can you really play) and stimulate his brain, but what?
        </mui.Typography>
        </mui.Grid>
        <mui.Grid item>
        <mui.Typography>
          Kevin had always joked about driving the school bus or selling popcorn and as fate would have it he came across an ad for a whole lot of popcorn-making equipment for sale.
          We had also met someone in the propcorn business who gave us some insight as to how it could be done and got us excited about a venture we could enjoy doing together.
          We are proud of our town and as it is known for its artisan products it made perfect sense to call ourselves Raglan Kettle Korn, and so it began.
        </mui.Typography>
        </mui.Grid>
        <mui.Grid item>
          <ThemeProvider theme={styles.theme}>
            <mui.Typography variant="h4">Why we do it</mui.Typography>
          </ThemeProvider>
        </mui.Grid>
        <mui.Grid item>
        <mui.Typography>
          We started off at small markets to hone our skills and perfect our recipe before approaching the much bigger farmers markets. We were amazed and delighted with the response and loyal customer base we developed in a very short time.
        </mui.Typography>
        </mui.Grid>
        <mui.Grid item>
        <mui.Typography>
          An important part of our ethos is helping others. Once we realized that we had a product everyone loved and the potential to earn good money at the farmers markets, we started to think how we could help other people into running their own stalls at a very low entry cost.
          Given Kevin's enthusiastic purchasing at the start, we had enough equipment to set up other operators fairly quickly.
        </mui.Typography>
        </mui.Grid>
        <mui.Grid item>
        <mui.Typography>
          Kevin and I still love being at the markets, it is incredibly satisfying to see how happy our popcorn makes people, especially the delight on the faces of children. We also really enjoy mentoring our operators and seeing them be successful and happy.
        </mui.Typography>
        </mui.Grid>
        <mui.Grid item>
        <mui.Typography>
          I have a feeling the story doesn't end here.
        </mui.Typography>
        </mui.Grid>
      </mui.Grid>
    </div>
  );
}
export default OurStoryText;