import * as mui from '@mui/material'
import type * as CSS from 'csstype';
import PreorderQuantity from './PreorderQuantity';
import PreorderMarket from './PreorderMarket';
import ContactDetails from './ContactDetails';
import PreorderSummary from './PreorderSummary';
import * as styles from '../../Styles';
import { useState, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc'
import * as constants from '../../constants';
import { API } from 'aws-amplify';


type stage = "quantity" | "market" | "details" | "summary" | "submitted";

type PreorderState = {
  preselectedMarketID?: number
}

function Preorder(props: PreorderState) {
  dayjs.extend(utc);

  const [orderTotal, setOrderTotal] = useState(0);
  const [smallBagQuantity, setSmallBagQuantity] = useState(0);
  const [largeBagQuantity, setLargeBagQuantity] = useState(0);
  const [XLBagQuantity, setXLBagQuantity] = useState(0);
  const [smallContainerQuantity, setSmallContainerQuantity] = useState(0);
  const [largeContainerQuantity, setLargeContainerQuantity] = useState(0);
  const [bucketQuantity, setBucketQuantity] = useState(0);

  const [selectedMarket, setSelectedMarket] = useState<number>(props.preselectedMarketID || 1);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [selectedTime, setSelectedTime] = useState<string>('8:00am');

  const [orderStage, setOrderStage] = useState<stage>("quantity");

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const markets = constants.markets;

  const callbacks = new Map<string, Function>([
    ['Small Bag', setSmallBagQuantity],
    ['Large Bag', setLargeBagQuantity],
    ['XL Bag', setXLBagQuantity],
    ['Small Container (refillable)', setSmallContainerQuantity],
    ['Large Container (refillable)', setLargeContainerQuantity],
    ['Bucket (refillable)', setBucketQuantity],
  ]);

  const quantities = new Map<string, number>([
    ['Small Bag', smallBagQuantity],
    ['Large Bag', largeBagQuantity],
    ['XL Bag', XLBagQuantity],
    ['Small Container (refillable)', smallContainerQuantity],
    ['Large Container (refillable)', largeContainerQuantity],
    ['Bucket (refillable)', bucketQuantity],
  ]);

  const smallBagPrice = 5.00;
  const largeBagPrice = 10.00;
  const XLBagPrice = 18.00;
  const smallContainerPrice = 7.50;
  const largeContainerPrice = 13.00;
  const bucketPrice = 40.00;

  const smallContainerRefillPrice = 5.00;
  const largeContainerRefillPrice = 10.00;
  const bucketRefillPrice = 35.00;

  const minOrderValue = 35;

  const products: Array<any> = [
    {name: 'Small Bag', price: smallBagPrice, refillPrice: null},
    {name: 'Large Bag', price: largeBagPrice, refillPrice: null},
    {name: 'XL Bag', price: XLBagPrice, refillPrice: null},
    {name: 'Small Container (refillable)', price: smallContainerPrice, refillPrice: smallContainerRefillPrice},
    {name: 'Large Container (refillable)', price: largeContainerPrice, refillPrice: largeContainerRefillPrice},
    {name: 'Bucket (refillable)', price: bucketPrice, refillPrice: bucketRefillPrice}
  ];


  const advanceOrderStage = () => {
    if (orderStage === "quantity") setOrderStage("market");
    else if (orderStage === "market") setOrderStage("details");
    else if (orderStage === "details") setOrderStage("summary");
    else {
      setOrderStage("submitted");
      postPreorder();
    }
  }

  const retreatOrderStage = () => {
    if (orderStage === "market") setOrderStage("quantity");
    else if (orderStage === "details") setOrderStage("market");
    else if (orderStage === "summary") setOrderStage("details");
  }

  const preorderBody = {
    'firstName': firstName,
    'lastName': lastName,
    'email': email,
    'phone': phone,
    'message': message,
    'market': constants.markets.get(selectedMarket)?.name,
    'selectedDate': selectedDate?.utc(true).format('DD MMMM'),
    'selectedTime': selectedTime,
    'quantities': Object.fromEntries(quantities),
    'orderTotal': orderTotal
  }

const postPreorder = () => {
    API.post(constants.API, '/preorder', {body: preorderBody})
    .then(response => {
        
    })
    .catch(error => {
        console.log(error)
    })
}

  useEffect(() => {

    const calculateOrderTotal = () => {
      let total = 0;
      total += smallBagQuantity * smallBagPrice;
      total += largeBagQuantity * largeBagPrice;
      total += XLBagQuantity * XLBagPrice;
      total += smallContainerQuantity * smallContainerPrice;
      total += largeContainerQuantity * largeContainerPrice;
      total += bucketQuantity * bucketPrice;
      return total;
    }

    setOrderTotal(calculateOrderTotal());
  }, [smallBagQuantity, largeBagQuantity, XLBagQuantity, smallContainerQuantity, largeContainerQuantity, bucketQuantity]);

  const sizesStyle: CSS.Properties | CSS.PropertiesHyphen  = {
    width: '95%',
    height: 'auto',
    opacity: '1',
    borderRadius: '50px',
  };

  return (
    <div>
      <mui.Grid container textAlign={'left'} gap='10px'>
          <p>
            Have a large order and don't want to wait on the kettle? Preorder online and collect at the nearest market! Your local team will be in touch to confirm your order.
          </p>
          <p>A minimum order value of ${minOrderValue} applies for online preorders. Preorders must be picked up by 11am or they may be sold to others. Preorder is subject to weather conditions at the market.</p>
        <mui.Grid item container flexDirection={'row'} justifyContent={'space-between'}>
          <mui.Grid item xs={5.8}>
            <mui.Card elevation={5} sx={styles.sizesCardStyle}>
            <mui.CardMedia
              component="img"
              sx={styles.sizesImageStyle}
              src={constants.imageURL + '/popcornbags_cut_resized2.jpg'}
              alt="Popcorn Bag Sizes"/>
            </mui.Card>
          </mui.Grid>
          <mui.Grid item xs={5.8}>
            <mui.Card elevation={5} sx={styles.sizesCardStyle}>
            <mui.CardMedia
              component="img"
              sx={styles.sizesImageStyle}
              src={constants.imageURL + '/popcorncontainers_cut_resized2.jpg'}
              alt="Popcorn Container Sizes"/>
            </mui.Card>
          </mui.Grid>
        </mui.Grid>
        <mui.Card style={styles.preorderCard} elevation={5}>
          {
          orderStage === "quantity" ?
          <PreorderQuantity 
            products={products} 
            callbacks={callbacks} 
            quantities={quantities} 
            orderTotal={orderTotal} 
            minOrderValue={minOrderValue}
            advance={advanceOrderStage}/>
          : orderStage === "market" ? 
          <PreorderMarket 
            markets={markets} 
            selectedMarket={selectedMarket} 
            setSelectedMarket={setSelectedMarket} 
            selectedDate={selectedDate} 
            setSelectedDate={setSelectedDate} 
            selectedTime={selectedTime} 
            setSelectedTime={setSelectedTime}
            advance={advanceOrderStage}
            back={retreatOrderStage}/>
          : orderStage === "details" ? 
          <ContactDetails 
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            email={email}
            setEmail={setEmail}
            phone={phone}
            setPhone={setPhone}
            message={message}
            setMessage={setMessage}
            advance={advanceOrderStage}
            back={retreatOrderStage}/>
          : orderStage === "summary" ? 
          <PreorderSummary
            products={products}
            quantities={quantities}
            orderTotal={orderTotal}
            marketName={markets.get(selectedMarket)?.name || ""}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            name={firstName + " " + lastName}
            email={email}
            phone={phone}
            message={message}
            advance={advanceOrderStage}
            back={retreatOrderStage}
          />
          :
          <mui.Grid container item justifyContent={'center'} sx={{padding:'5%'}} gap={3} flexDirection={'column'}>
            <mui.Grid container flexDirection={'row'} justifyContent={'center'}>
              <img src={constants.imageURL + '/rkklogo.png'} alt="rkk logo" width='150px' height='auto'/>
            </mui.Grid>
            <mui.ThemeProvider theme={styles.theme}>
                <mui.Typography variant='h6' display='block'>
                    Preorder submitted!
                </mui.Typography>
                <mui.Typography variant='h6' display='block'>
                    You will receive a confirmation email within 24 hours, check your junk mail if necessary.
                </mui.Typography>
            </mui.ThemeProvider>
            </mui.Grid>
          } 
        </mui.Card>
      </mui.Grid>
    </div>
  );
}
export default Preorder;