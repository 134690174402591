import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import * as styles from '../Styles';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import * as constants from '../constants';
import * as mui from '@mui/material';
import { ThemeProvider } from '@mui/material';

const Home = () => {

    const navigate = useNavigate();

    return (
        <ThemeProvider theme={styles.theme}>
            <Helmet><title>Raglan Kettle Korn | Home</title></Helmet>
            <Grid container flexDirection={'column'} sx={{xs: 12}}>
                <Grid container item flexDirection={'row'} sx={{xs:12, justifyContent: 'center'}}>
                    <CardMedia
                        component="img"
                        sx={styles.splashImageStyle}
                        src={constants.imageURL + '/home_image.jpg'}
                        alt="Home Image"/>
                </Grid>
                <Grid container flexDirection={'column'} position={'absolute'} spacing={2} sx={{marginTop: '8%'}}>
                <Grid container item flexDirection={'row'}>
                    <Grid item xs={4.5} md={4.5}></Grid>
                    <Grid item container xs={3} md={3} justifyContent={'center'}>
                        <mui.Card elevation={10} sx={{minWidth: '250px', height:'auto', objectFit: "fill"}}>
                            <CardMedia
                            component="img"
                            sx={{minWidth: '250px', height:'auto', objectFit: "fill"}}
                            src={constants.imageURL + '/rkklogo.jpg'}
                            alt="rkk logo"/>
                        </mui.Card>
                    </Grid>
                    <Grid item xs={4.5} md={4.5}></Grid>
                </Grid>
                <Grid container item flexDirection={'column'} sx={{alignItems: 'center', justifyContent: 'center'}} gap={2}>
                    <Button 
                    variant="contained"
                    sx={{width: '25%', minWidth: '300px', fontSize: 22, borderRadius: 20, textTransform:'none'}}
                    onClick={() => {navigate('../order')}}>
                        Place Market Preorder</Button>
                    <Grid container item flexDirection={'row'} xs={12} justifyContent='center' gap={2}>
                        <Button 
                        variant="contained"
                        sx={{width: '20%', minWidth: '200px', height: 40, fontSize: 15, borderRadius: 20, textTransform:'none'}}
                        onClick={() => {navigate('../markets')}}>
                            Find Your Local Market</Button>
                    {/* <Button 
                    variant="contained"
                    sx={{width: '12%', minWidth: '200px', height: 40, fontSize: 15, borderRadius: 20, textTransform:'none'}}
                    onClick={() => {navigate('../markets')}}>
                        Find Your Local Market</Button>
                    <Button 
                    variant="contained"
                    sx={{width: '12%', minWidth: '200px', height: 40, fontSize: 15, borderRadius: 20, textTransform:'none'}}
                    onClick={() => {navigate('../stores')}}>
                        Find Your Local Store</Button> */}
                    </Grid>
                </Grid>
                </Grid>
            </Grid>
        </ThemeProvider>
    )
}

export default Home;