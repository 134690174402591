import type * as CSS from 'csstype';
import { createTheme } from '@mui/material';
interface Style extends CSS.Properties, CSS.PropertiesHyphen {}


// export const theme = createTheme({
//     typography: {
//       fontFamily: [
//         'Caprasimo',
//         'cursive',
//       ].join(','),
//     },});
export const theme = createTheme({
    palette: {
        primary: {
            main: '#000000'
        }
      },
    typography: {
        fontFamily: [
        'Roboto',
        'sans-serif',
        ].join(','),
        h2: {
            fontWeight: 800
        },
        h3: {
            fontWeight: 800
        },
        h4: {
            fontWeight: 800
        },
        h5: {
            fontWeight: 800
        },
        h6: {
            fontWeight: 800
        }
    },
});

export const storyImageStyle: Style = {
    objectFit: "cover",
    width: '100%',
    height: '100%',
    // maxHeight: '600px',
    opacity: '1',
    borderRadius: '50px'
};

export const storyCardStyle: Style = {
    width: '80%',
    height: 'auto',
    // maxHeight: '600px',
    borderRadius: '50px'
}

export const storySquareImageStyle: Style = {
    objectFit: "cover",
    width: '100%',
    height: '100%',
    opacity: '1',
    borderRadius: '5px'
};

export const storySquareCardStyle: Style = {
    width: '80%',
    height: 'auto',
    borderRadius: '5px'
}

export const marketsCardStyle: Style = {
    width: '50%',
    maxHeight: '300px',
    borderRadius: '50px'
}

export const marketsImageStyle: Style = {
    // objectFit: "fill",
    width: '100%',
    height: '100%',
    maxHeight: '600px',
    borderRadius: '50px'
};

export const splashImageStyle: Style = {
    width: '92%',
    height: '79vh',
    // maxHeight: '10%',
    opacity: '1',
    borderRadius: '50px'
};

export const contactCard: CSS.Properties = {
    display: "flex",
    height: "auto",
    // maxHeight: '50vh',
    // minHeight: "10vh",
    borderRadius: '30px',
    width: "auto",
    minWidth: '30%',
    margin: "5%",
    padding: "15px",
    // backgroundColor: '#fff7ed',
    opacity: '1.0'
}

export const preorderCard: CSS.Properties = {
    display: "block",
    height: "auto",
    width: "100%",
    padding: "20px",
    // backgroundColor: '#fff7ed',
    opacity: '1.0'
}

export const sizesCardStyle: Style = {
    width: '100%',
    height: 'auto',
    maxHeight: '600px',
    borderRadius: '50px'
}

export const sizesImageStyle: Style = {
    objectFit: "contain",
    width: '100%',
    height: '100%',
    maxHeight: '600px',
    opacity: '1',
    borderRadius: '50px'
};

export const menuDrawerStyle: Style = {
    width: 'auto',
    backgroundColor: '#fff7ed'
}