import * as mui from '@mui/material'
import OurMarketsEntry from '../components/OurMarketsEntry';
import * as styles from '../Styles';
import {ThemeProvider } from '@mui/material';
import * as constants from '../constants';
import { Helmet } from 'react-helmet';

const OurMarkets = () => {

    return (
        <div>
            <Helmet><title>Raglan Kettle Korn | Markets</title></Helmet>
            <mui.Grid container flexDirection='column'gap='0px'>
                <ThemeProvider theme={styles.theme}>
                    <mui.Typography variant="h2" textAlign='left' paddingLeft='7%' sx={{color: '#FE0000', textDecoration: 'underline', textDecorationColor: 'black'}}>
                        Auckland
                    </mui.Typography>
                </ThemeProvider>
                <OurMarketsEntry market={constants.markets.get(2) as Market} text={constants.greyLynnMarketText} imageName={'/greylynn.jpg'}/>
                <mui.Grid item xs={12} paddingLeft='50px' paddingRight='50px'><hr></hr></mui.Grid>
                <ThemeProvider theme={styles.theme}>
                    <mui.Typography variant="h2" textAlign='left' paddingLeft='7%' sx={{color: '#FE0000', textDecoration: 'underline', textDecorationColor: 'black'}}>
                        Bay of Plenty
                    </mui.Typography>
                </ThemeProvider>
                <OurMarketsEntry market={constants.markets.get(4) as Market} text={constants.taurangaMarketText} imageName={'/teamryan.jpg'}/>
                <mui.Grid item xs={12} paddingLeft='50px' paddingRight='50px'><hr></hr></mui.Grid>
                <ThemeProvider theme={styles.theme}>
                    <mui.Typography variant="h2" textAlign='left' paddingLeft='7%' sx={{color: '#FE0000', textDecoration: 'underline', textDecorationColor: 'black'}}>
                        Taupō
                    </mui.Typography>
                </ThemeProvider>
                <OurMarketsEntry market={constants.markets.get(5) as Market} text={constants.taupoMarketText} imageName={'/teamjason.jpg'}/>
                <mui.Grid item xs={12} paddingLeft='50px' paddingRight='50px'><hr></hr></mui.Grid>
                <ThemeProvider theme={styles.theme}>
                    <mui.Typography variant="h2" textAlign='left' paddingLeft='7%' sx={{color: '#FE0000', textDecoration: 'underline', textDecorationColor: 'black'}}>
                        Waikato
                    </mui.Typography>
                </ThemeProvider>
                <OurMarketsEntry market={constants.markets.get(1) as Market} text={constants.cambridgeMarketText} imageName={'/cambridge.jpg'}/>
                <OurMarketsEntry market={constants.markets.get(3) as Market} text={constants.hamiltonMarketText} imageName={'/hamilton.jpg'}/>
    
            </mui.Grid>
        </div>
    )
}

export default OurMarkets;