import * as mui from '@mui/material'
import OurStoryText from '../components/OurStoryText';
import * as styles from '../Styles';
import {ThemeProvider } from '@mui/material';
import { Helmet } from 'react-helmet';
import * as constants from '../constants';

const OurStory = () => {

    return (
        <div>
            <Helmet><title>Raglan Kettle Korn | Our Story</title></Helmet>
            {/* <ThemeProvider theme={styles.theme}>
                <mui.Typography variant="h3">
                    Our Story
                </mui.Typography>
            </ThemeProvider> */}
            <mui.Grid container display='flex' flexDirection='row' justifyContent='center' sx={{}}>
                <mui.Grid container item flexDirection={'column'} xs={10} md={5}>
                    <mui.Grid item sx={{paddingLeft: '10%', paddingTop: '4%'}}>
                        <OurStoryText/>
                    </mui.Grid>
                </mui.Grid>
                <mui.Grid container item flexDirection={'column'} xs={0} md={5}>
                    <mui.Grid item>
                        <mui.Grid item container sx={{padding: '5%', justifyContent: 'center'}}>
                            <mui.Card elevation={5} sx={styles.storyCardStyle}>
                            <mui.CardMedia
                            component="img"
                            sx={styles.storyImageStyle}
                            src={constants.imageURL + '/suzanne_kevin_helicopter_cropped.jpg'}
                            alt="Suzanne and Kevin"/>
                            </mui.Card>
                        </mui.Grid>
                    </mui.Grid>
                </mui.Grid>

            </mui.Grid>
        </div>
    )
}

export default OurStory;