import * as mui from '@mui/material'
import * as styles from '../Styles';
import { ThemeProvider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as constants from '../constants';

type MarketDetails = {
  market: Market,
  text: string,
  imageName: string
}

function OurMarketsEntry(props: MarketDetails) {

  const navigate = useNavigate();

  return (
    <ThemeProvider theme={styles.theme}>
      <mui.Grid container flexDirection={'row'} textAlign={'left'} justifyContent='center'>
        <mui.Grid container item xs={12} md={5} flexDirection={'column'} sx={{paddingLeft: '2%'}} justifyContent={'center'} gap={'20px'}>
          {/* <ThemeProvider theme={styles.theme}> */}
            <mui.Typography variant="h3" color={'black'}>{props.market.name}</mui.Typography>
          {/* </ThemeProvider> */}
          <mui.Typography>{props.text}</mui.Typography>
          <mui.Grid item container justifyContent={'flex-end'}>
            <mui.Grid item xs={12} md={4}>
              <mui.Button 
                variant="contained"
                sx={{width: 'auto', minWidth: '350px', height: 40, fontSize: 16, borderRadius: 20, textTransform:'none'}}
                onClick={() => {navigate('../order', {state: {preselectedMarketID: props.market.id}})}}>
                    Place Market Preorder
              </mui.Button>
            </mui.Grid>
          </mui.Grid>
        </mui.Grid>
        <mui.Grid container item xs={0} md={5} flexDirection={'column'}>
          <mui.Grid item container xs={0} md={12} sx={{justifyContent: 'center', padding: '2%'}}>
            <mui.Card elevation={5} sx={styles.marketsCardStyle}>
            <mui.CardMedia
              component="img"
              sx={styles.marketsImageStyle}
              src={constants.imageURL + props.imageName}
              alt={props.imageName}/>
            </mui.Card>
          </mui.Grid>
        </mui.Grid>
      </mui.Grid>
      </ThemeProvider>
  );
}

export default OurMarketsEntry;