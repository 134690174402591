import * as mui from '@mui/material';

type PopcornItemQuantitySelector = {
  name: string,
  price: number,
  refillPrice: number,
  quantity: number,
  setQuantity: Function
}

function PreorderItemQuantitySelector(props: PopcornItemQuantitySelector) {

  const onAddQuantity = () => {
    props.setQuantity((qty) => qty + 1);
  }

  const onRemoveQuantity = () => {
    if (props.quantity > 0) {
      props.setQuantity((qty) => qty - 1);
    }
  }

  return (
    <div>
        <mui.Grid container flexDirection={'row'}>
          <mui.Grid item container justifyContent='center' alignItems='center' xs={1.5}>
            <mui.Button variant="contained" sx={{minWidth: 10, width: 10, height:30}} onClick={onRemoveQuantity}>-</mui.Button>
          </mui.Grid>
          <mui.Grid item xs={1.5}>
            <mui.TextField
              id="small-bag-quantity"
              label=""
              inputProps={{
                style: {textAlign: 'center', minHeight: 20, height: 20}
              }}
              InputProps={{
                readOnly: true,
              }}
              variant="filled"
              size="small"
              disabled
              value={props.quantity}
            /> 
          </mui.Grid>
          <mui.Grid item container justifyContent='center' alignItems='center' xs={1.5}>
            <mui.Button variant="contained" sx={{minWidth: 10, width: 10, height:30}} onClick={onAddQuantity}>+</mui.Button>
          </mui.Grid>
          <mui.Grid item container justifyContent='flex-start' alignItems='center' gap='10px' xs={5.5}>
            <mui.Grid item xs={12}>
              <mui.Typography variant='button'>{props.name}</mui.Typography>
            </mui.Grid>
          </mui.Grid>
          <mui.Grid item container flexDirection={'row'} justifyContent='flex-start' alignItems='center' xs={2}>
            <mui.Grid item xs={10}><mui.Typography fontSize={18}>${props.price.toFixed(2) + (props.refillPrice ? ' ($' + props.refillPrice + ")" : "")}</mui.Typography></mui.Grid>
              {/* {props.refillPrice && <mui.Grid item xs={2}><mui.Typography fontSize={18}>(${props.refillPrice})</mui.Typography></mui.Grid>} */}
          </mui.Grid>
        </mui.Grid>
    </div>
  );
}
export default PreorderItemQuantitySelector;