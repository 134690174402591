import * as mui from '@mui/material'
import OurArtisanPopcornText from '../components/OurArtisanPopcornText';
import { ThemeProvider } from '@mui/material';
import * as styles from '../Styles';
import { Helmet } from 'react-helmet';
import * as constants from '../constants';

const OurArtisanPopcorn = () => {

    return (
        <div>
            <Helmet><title>Raglan Kettle Korn | Popcorn</title></Helmet>
            <mui.Grid container display='flex' flexDirection='row' justifyContent='center'>
                <mui.Grid container item flexDirection={'column'} xs={10} md={5}>
                    <mui.Grid item sx={{paddingLeft: '10%', paddingTop: '4%'}}>
                        <OurArtisanPopcornText/>
                        <mui.Card elevation={5} sx={styles.storySquareCardStyle}>
                        <mui.CardMedia
                        component="img"
                        sx={styles.storySquareImageStyle}
                        src={constants.imageURL + '/nutritional.jpg'}
                        alt="Nutrtional Information"/>
                        </mui.Card>
                    </mui.Grid>
                </mui.Grid>
                <mui.Grid container item flexDirection={'column'} xs={0} md={5}>
                    <mui.Grid item container sx={{padding: '5%', justifyContent: 'center'}} gap={3}>
                        <mui.Card elevation={5} sx={styles.storyCardStyle}>
                        <mui.CardMedia
                        component="img"
                        sx={styles.storyImageStyle}
                        src={constants.imageURL + '/pour.jpg'}
                        alt="Popcorn Pour"/>
                        </mui.Card>
                        <mui.Card elevation={5} sx={styles.storyCardStyle}>
                        <mui.CardMedia
                        component="img"
                        sx={styles.storyImageStyle}
                        src={constants.imageURL + '/popcorn1.jpg'}
                        alt="Popcorn Scoop"/>
                        </mui.Card>
                        <mui.Card elevation={5} sx={styles.storyCardStyle}>
                        <mui.CardMedia
                        component="img"
                        sx={styles.storyImageStyle}
                        src={constants.imageURL + '/mushroombutterfly.webp'}
                        alt="Kernel types"/>
                        </mui.Card>
                    </mui.Grid>
                </mui.Grid>

            </mui.Grid>
        </div>
    )
}

export default OurArtisanPopcorn;