import * as mui from '@mui/material';

type PreorderState = {
  product: string,
  quantity: number,
  subtotal: number
}

function PreorderSummaryItemSubtotal(props: PreorderState) {

  return (
    <div>
        <mui.Grid container flexDirection={'row'}>
          <mui.Grid item justifyContent='left' alignItems='center' xs={1}>
            <mui.Typography>{props.quantity}x</mui.Typography>
          </mui.Grid>
          <mui.Grid item justifyContent='left' alignItems='center' xs={9}>
            <mui.Typography variant="button">{props.product}</mui.Typography>
          </mui.Grid>
          <mui.Grid item justifyContent='right' alignItems='center' xs={2}>
            <mui.Typography>${props.subtotal.toFixed(2)}</mui.Typography>
          </mui.Grid>
        </mui.Grid>
    </div>
  );
}
export default PreorderSummaryItemSubtotal;