import * as mui from '@mui/material';
import * as styles from '../../Styles';
import PreorderSummaryItemSubtotal from './PreorderSummaryItemSubtotal';
import { Dayjs } from 'dayjs';

type PreorderState = {
  products: Array<any>,
  quantities: Map<string, number>,
  orderTotal: number,
  marketName: string,
  selectedDate: Dayjs | null,
  selectedTime: string,
  name: string
  email: string,
  phone: string,
  message: string,
  advance: Function,
  back: Function
}

function PreorderDetails(props: PreorderState) {

  let productToQuantityAndSubtotal = Array<any>();
  
  props.products.forEach((product) => {
    const quantity = props.quantities.get(product.name) || 0;
    if (quantity > 0) {
      productToQuantityAndSubtotal.push([product.name, quantity, product.price * quantity]);
    }
  });

  // const collectString = "Collect at {props.marketName} on {props.selectedDate} at {props.selectedTime}.";

  return (
    <div>
      <mui.Grid container flexDirection={'column'} gap={'15px'}>
        <mui.Grid item>
          <mui.ThemeProvider theme={styles.theme}>
            <mui.Typography variant="h4">Preorder Summary</mui.Typography>
          </mui.ThemeProvider>
          <mui.Typography>Preorder is not submitted until you click 'Submit Preorder'.</mui.Typography>
        </mui.Grid>
        <mui.Grid item>
          {productToQuantityAndSubtotal.map((value) => <PreorderSummaryItemSubtotal key={value[0]} product={value[0]} quantity={value[1]} subtotal={value[2]}/>)}
          <hr></hr>
          <mui.Grid container item flexDirection={'row'}>
            <mui.Grid item xs={10}>
              <mui.Typography variant="h6">Preorder Total: (pay at market)</mui.Typography>
            </mui.Grid>
            <mui.Grid item xs={1} textAlign='right'>
              <mui.Typography key={props.orderTotal} variant='h6'>${props.orderTotal.toFixed(2)}</mui.Typography>
            </mui.Grid>
          </mui.Grid>
        </mui.Grid>
        <mui.Grid container item flexDirection={'column'}>
          <mui.Typography variant="h6">Collection</mui.Typography>
          <mui.Typography marginLeft={'10px'}>{props.marketName} on {props.selectedDate?.format('DD MMMM')} at {props.selectedTime}.</mui.Typography>
        </mui.Grid>
        <mui.Grid item>
          <mui.Typography variant="h6">Contact Information</mui.Typography>
          <mui.Grid container item flexDirection={'column'} xs={10} gap='5px' marginLeft={'10px'}>
          <mui.Grid item>
            <mui.Typography>Name: {props.name}</mui.Typography>
          </mui.Grid>
          <mui.Grid item>
            <mui.Typography>Email: {props.email}</mui.Typography>
          </mui.Grid>
            {props.phone && (<mui.Typography>Phone: {props.phone}</mui.Typography>)}
            {props.message && (<mui.Typography>Note: {props.message}</mui.Typography>)}
          </mui.Grid>
        </mui.Grid>
        <mui.Grid container flexDirection={'row'} justifyContent={'space-between'}>
          <mui.Grid item xs={2}>
          <mui.Button
            fullWidth
            variant='contained'
            onClick={() => {props.back()}}
          >
            Back
          </mui.Button>
          </mui.Grid>
          <mui.Grid item xs={9.5}>
          <mui.Button 
            fullWidth
            variant="contained"
            disabled={false}
            onClick={() => {props.advance()}}>
                Submit Preorder &gt;
          </mui.Button>
          </mui.Grid>
        </mui.Grid>
      </mui.Grid>
    </div>
  );
}
export default PreorderDetails;