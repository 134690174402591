import { Helmet } from 'react-helmet';
import * as mui from '@mui/material';

function Footer() {
    return (
        <div>
            <Helmet>
                <div id="fb-root"></div>
                <script async defer crossOrigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v17.0" nonce="kkyOVZ3J"></script>
            </Helmet>
            <mui.Grid
            sx={{
                // borderTop: "1px solid grey",
                backgroundColor: '#dfd4c6',
                marginTop: "1%",
                // height: '100%',
                width: '100%',
                p: 0.5,
                // position: 'fixed',
                // left:0,
                // bottom:0,
                // right:0
            }}
            >
            <mui.Grid container flexDirection={'column'} justifyContent={'flex-start'} sx={{}} gap={0.5}>
                <mui.Grid item>
                    <mui.Typography sx={{fontStyle:'italic'}}>Follow us on Facebook for weekly updates on our operators' locations.</mui.Typography>
                </mui.Grid>
                <mui.Grid item xs={12} textAlign='center' flexDirection={'row'} justifyContent={'center'} sx={{paddingLeft: '0%'}}>
                    <div 
                        className="fb-page" 
                        data-href="https://www.facebook.com/profile.php?id=100083135202163" 
                        data-tabs="posts" 
                        data-width=""
                        data-height="50" 
                        data-small-header="true" 
                        data-adapt-container-width="true" 
                        data-hide-cover="false" 
                        data-show-facepile="true">
                        <blockquote cite="https://www.facebook.com/profile.php?id=100083135202163" className="fb-xfbml-parse-ignore">
                            <a href="https://www.facebook.com/profile.php?id=100083135202163">Raglan Kettle Korn</a>
                        </blockquote>
                    </div>
                </mui.Grid>
            </mui.Grid>
            </mui.Grid>
        </div>
    )
}

export default Footer;