import * as mui from '@mui/material'
import Preorder from '../components/Preorder/Preorder';
import * as styles from '../Styles';
import { ThemeProvider } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as constants from '../constants';

const PlacePreorder = () => {

    const location = useLocation();

    return (
        <ThemeProvider theme={styles.theme}>
            <Helmet><title>Raglan Kettle Korn | Preorder</title></Helmet>
            <mui.Grid container item display='flex' flexDirection='row' xs={12}>
                <mui.Grid container item flexDirection={'column'} xs={12} md={6}>
                    <mui.Grid item sx={{padding: '5%'}}>
                        <Preorder preselectedMarketID={location.state?.preselectedMarketID || 1}/>
                    </mui.Grid>
                </mui.Grid>
                <mui.Grid item container flexDirection={'column'} xs={0} md={6}>
                    <mui.Grid item>
                        <mui.Grid item container sx={{padding: '5%', justifyContent: 'center', gap: '50px'}}>
                            <mui.Card elevation={5} sx={styles.storyCardStyle}>
                                <mui.CardMedia
                                component="img"
                                sx={styles.storyImageStyle}
                                src={constants.imageURL + '/customers1.jpg'}
                                alt="Happy Customer"/>
                            </mui.Card>
                            <mui.Card elevation={5} sx={styles.storyCardStyle}>
                                <mui.CardMedia
                                component="img"
                                sx={styles.storyImageStyle}
                                src={constants.imageURL + '/customers4.jpg'}
                                alt="Happy Customer"/>
                            </mui.Card>
                        </mui.Grid>
                    </mui.Grid>
                </mui.Grid>

            </mui.Grid>
        </ThemeProvider>
    )
}

export default PlacePreorder;